import React from "react";

import note from '../../img/Home/note.png'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom"

function Trading () {

    const { t } = useTranslation();

    return(
        <div className="bg-[#F7F4F8] z-50 relative">
            <div className="max-w-[1280px] xl:mx-auto pt-[120px] xl:pt-[200px] pb-[40px] xl:pb-[80px] mx-[20px]">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div>
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium">{t('main.48')}</h1>
                        <p className="xl:max-w-[600px] textcolor mt-[40px] roboto font-light">{t('main.49')}</p>
                        <p className="xl:max-w-[600px] textcolor mt-[20px] roboto font-light">{t('main.50')}</p>
                        <Link to='https://user.cgl-limited.net'><div className="button border border-[#0349DA] mt-[40px] z-50 relative">
                            <p className="text-[#0349DA] underline">{t('main.47')}</p>
                        </div></Link>
                    </div>
                    <Fade right><div className="mt-[60px] xl:mt-0 mx-auto xl:mx-0">
                        <img src={note} alt="" />
                    </div></Fade>
                </div>
                <div className="flex flex-col xl:flex-row justify-between mt-[80px] xl:mt-[160px]">
                    <div>
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium">{t('main.51')}</h1>
                        <p className="xl:max-w-[600px] textcolor mt-[40px] roboto font-light">{t('main.52')}</p>
                    </div>
                    <div className="mt-[20px] xl:mt-[57px]">
                        <p className="xl:max-w-[600px] textcolor mt-[40px] roboto font-light">{t('main.53')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Trading;