import React, { useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import { Link } from "react-router-dom"

import 'swiper/css/navigation';
import 'swiper/css';
import { useTranslation } from "react-i18next";

function CollaborativeLearning () {

    const { t } = useTranslation();

    const [swiper1, setSwiper1] = useState({});

    const [bgColorPrev1, setBgColorPrev1] = useState('transparent');
    const [bgColorNext1, setBgColorNext1] = useState('transparent');

    const handleClickPrev1 = () => {
        if (bgColorPrev1 === 'transparent') {
            setBgColorPrev1('#D1DEF8');
          // Change back to transparent after 1 second (1000 milliseconds)
          setTimeout(() => {
            setBgColorPrev1('transparent');
          }, 100);
        }
        swiper1.slidePrev()
      };
    const handleClickNext1 = () => {
        if (bgColorNext1 === 'transparent') {
            setBgColorNext1('#D1DEF8');
            // Change back to transparent after 1 second (1000 milliseconds)
            setTimeout(() => {
                setBgColorNext1('transparent');
            }, 100);
        }
        swiper1.slideNext()
    };

    return(
        <div className="bg-[#F7F4F8] z-50 relative">
            <div className="max-w-[1280px] xl:mx-auto pt-[120px] xl:pt-[200px] mx-[20px]">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div>
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium">{t('CommunityForum.1')}</h1>
                        <p className="xl:max-w-[600px] textcolor mt-[50px] roboto font-light">{t('CommunityForum.2')}</p>
                        <p className="xl:max-w-[600px] textcolor mt-[20px] roboto font-light">{t('CommunityForum.3')}</p>
                        <Link to='https://user.cgl-limited.net'><div className="button bg-[#0349DA] mt-[40px] z-50 relative">
                            Start Trading!
                        </div></Link>
                    </div>
                    <div className="xl:w-[600px] mt-[40px] xl:mt-0">
                        <Swiper
                            modules={[Navigation]}
                            slidesPerView={1}
                            onInit={(e) => {
                                setSwiper1(e);
                            }}
                            loop={true}
                        >
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[330px] bg-[#D1DEF8]">
                                    <div className="pt-[20px] mx-[20px] xl:mx-[40px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                            <path d="M28.5469 23.3438C26.4627 23.3438 24.6262 24.369 23.4726 25.9281L13.4993 20.8212C13.6649 20.2568 13.7812 19.6719 13.7812 19.0547C13.7812 18.2175 13.6096 17.4217 13.3131 16.6897L23.7506 10.4088C24.9122 11.772 26.6195 12.6562 28.5469 12.6562C32.0364 12.6562 34.875 9.81766 34.875 6.32812C34.875 2.83859 32.0364 0 28.5469 0C25.0573 0 22.2188 2.83859 22.2188 6.32812C22.2188 7.13229 22.3843 7.89511 22.659 8.6033L12.1904 14.9026C11.0299 13.5799 9.34713 12.7266 7.45312 12.7266C3.96359 12.7266 1.125 15.5651 1.125 19.0547C1.125 22.5442 3.96359 25.3828 7.45312 25.3828C9.57164 25.3828 11.4392 24.3275 12.5884 22.7246L22.5289 27.8148C22.3458 28.4059 22.2188 29.0215 22.2188 29.6719C22.2188 33.1614 25.0573 36 28.5469 36C32.0364 36 34.875 33.1614 34.875 29.6719C34.875 26.1823 32.0364 23.3438 28.5469 23.3438Z" fill="#001F61"/>
                                        </svg>
                                        <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('CommunityForum.4')}</h1>
                                        <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('CommunityForum.5')}</p>
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[330px] bg-[#D1DEF8]">
                                    <div className="pt-[20px] mx-[20px] xl:mx-[40px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                            <path d="M27 1.5H9C7.01161 1.50238 5.10534 2.29332 3.69933 3.69933C2.29332 5.10534 1.50238 7.01161 1.5 9V21C1.50218 22.7283 2.10014 24.403 3.19308 25.7419C4.28602 27.0807 5.80712 28.0019 7.5 28.35V33C7.49996 33.2716 7.57364 33.538 7.71319 33.771C7.85273 34.004 8.05291 34.1947 8.29235 34.3228C8.5318 34.4509 8.80153 34.5116 9.07277 34.4985C9.34402 34.4853 9.6066 34.3987 9.8325 34.248L18.45 28.5H27C28.9884 28.4976 30.8947 27.7067 32.3007 26.3007C33.7067 24.8947 34.4976 22.9884 34.5 21V9C34.4976 7.01161 33.7067 5.10534 32.3007 3.69933C30.8947 2.29332 28.9884 1.50238 27 1.5ZM24 19.5H12C11.6022 19.5 11.2206 19.342 10.9393 19.0607C10.658 18.7794 10.5 18.3978 10.5 18C10.5 17.6022 10.658 17.2206 10.9393 16.9393C11.2206 16.658 11.6022 16.5 12 16.5H24C24.3978 16.5 24.7794 16.658 25.0607 16.9393C25.342 17.2206 25.5 17.6022 25.5 18C25.5 18.3978 25.342 18.7794 25.0607 19.0607C24.7794 19.342 24.3978 19.5 24 19.5ZM27 13.5H9C8.60218 13.5 8.22064 13.342 7.93934 13.0607C7.65804 12.7794 7.5 12.3978 7.5 12C7.5 11.6022 7.65804 11.2206 7.93934 10.9393C8.22064 10.658 8.60218 10.5 9 10.5H27C27.3978 10.5 27.7794 10.658 28.0607 10.9393C28.342 11.2206 28.5 11.6022 28.5 12C28.5 12.3978 28.342 12.7794 28.0607 13.0607C27.7794 13.342 27.3978 13.5 27 13.5Z" fill="#001F61"/>
                                        </svg>
                                        <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('CommunityForum.6')}</h1>
                                        <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('CommunityForum.7')}</p>
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[330px] bg-[#D1DEF8]">
                                    <div className="pt-[20px] mx-[20px] xl:mx-[40px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                            <path d="M12.375 9.00112H15.0919V17.1345C16.8265 16.0439 19.1839 16.0451 20.9081 17.1176C20.9081 17.1176 20.9081 9.00112 20.9081 9.00112H23.625C24.1074 9.01833 24.3763 8.3742 24.0244 8.03927C24.0244 8.03928 18.3994 2.41453 18.3994 2.41453C18.18 2.19516 17.82 2.19516 17.6006 2.41453L11.9756 8.03928C11.6264 8.37515 11.8899 9.01744 12.375 9.00112Z" fill="#001F61"/>
                                            <path d="M23.4956 15.9757C23.58 16.1894 23.7881 16.3244 24.0131 16.3244H26.0775V20.4811C27.529 19.6049 29.5059 19.5871 30.9544 20.4923C30.9544 20.4923 30.9544 16.3244 30.9544 16.3244H33.0131C33.4959 16.3413 33.7642 15.6977 33.4125 15.3626C33.4125 15.3626 28.9125 10.8628 28.9125 10.8628C28.6931 10.6434 28.3331 10.6434 28.1137 10.8628L23.6137 15.3626C23.4563 15.5257 23.4056 15.7675 23.4956 15.9757Z" fill="#001F61"/>
                                            <path d="M2.98688 16.3244H5.04561V20.4754C6.51609 19.6295 8.44209 19.6336 9.92251 20.4755C9.92251 20.4754 9.92251 16.3244 9.92251 16.3244H11.9869C12.2119 16.3244 12.42 16.1894 12.5044 15.9757C12.5944 15.7675 12.5437 15.5257 12.3863 15.3626L7.88626 10.8628C7.66688 10.6434 7.30687 10.6434 7.08749 10.8628L2.58749 15.3626C2.23799 15.6981 2.50197 16.3409 2.98688 16.3244Z" fill="#001F61"/>
                                            <path d="M20.3512 25.6727C24.2502 23.2653 22.2282 17.3243 17.9999 17.4381C13.535 17.4552 11.8816 23.3372 15.6545 25.6783C13.2806 26.612 11.5987 28.9238 11.5987 31.6293V33.1874C11.5987 33.4967 11.8462 33.7498 12.1612 33.7498H23.8388C24.1538 33.7498 24.4013 33.4967 24.4013 33.1874V31.6293C24.4406 29.0476 22.7475 26.5952 20.3512 25.6727Z" fill="#001F61"/>
                                            <path d="M11.1656 24.6212C11.1721 21.3655 7.33017 19.8879 5.04552 21.8483C3.34829 23.2247 3.41265 26.3597 5.37197 27.6249C3.53251 28.4405 2.25 30.2741 2.25 32.4115V33.1878C2.25 33.4971 2.50313 33.7502 2.8125 33.7502H10.575C10.395 33.3959 10.5019 32.0291 10.4738 31.6297C10.4738 30.5666 10.6875 29.5485 11.0981 28.6261C10.665 28.2155 10.1588 27.8724 9.60189 27.6249C10.5469 26.9555 11.1656 25.8587 11.1656 24.6212Z" fill="#001F61"/>
                                            <path d="M32.22 28.7045C31.7531 28.2433 31.2131 27.8833 30.6338 27.6246C32.5287 26.3843 32.7175 23.3194 30.9543 21.8761C28.7033 19.7617 24.7795 21.4962 24.8344 24.621C24.8344 25.864 25.4588 26.9609 26.3981 27.6246C25.8469 27.8721 25.3406 28.2095 24.9075 28.6258C25.3125 29.5595 25.5263 30.5776 25.5263 31.6294C25.5038 32.0288 25.605 33.3899 25.425 33.7499H33.1875C33.4969 33.7499 33.75 33.4968 33.75 33.1875V32.4112C33.75 31.0163 33.2044 29.7001 32.22 28.7045Z" fill="#001F61"/>
                                        </svg>
                                        <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('CommunityForum.8')}</h1>
                                        <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('CommunityForum.9')}</p>
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[330px] bg-[#D1DEF8]">
                                    <div className="pt-[20px] mx-[20px] xl:mx-[40px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                        <g clip-path="url(#clip0_300_2445)">
                                            <path d="M1.88437 9.91357V26.0866L9.79453 21.5196V14.4805L1.88437 9.91357Z" fill="#001F61"/>
                                            <path d="M11.9039 15.6982V21.5196L16.9453 24.4302V18.6089L11.9039 15.6982Z" fill="#001F61"/>
                                            <path d="M10.8492 23.3462L2.939 27.9132L16.9453 35.9999V26.8658L10.8492 23.3462Z" fill="#001F61"/>
                                            <path d="M19.0547 18.6089V24.4302L24.0961 21.5196V15.6982L19.0547 18.6089Z" fill="#001F61"/>
                                            <path d="M26.2054 14.4805V21.5196L34.1156 26.0866V9.91357L26.2054 14.4805Z" fill="#001F61"/>
                                            <path d="M25.1508 23.3462L19.0547 26.8658V35.9999L33.061 27.9132L25.1508 23.3462Z" fill="#001F61"/>
                                            <path d="M19.0547 0V9.13409L25.1508 12.6536L33.0609 8.08671L19.0547 0Z" fill="#001F61"/>
                                            <path d="M2.93906 8.08671L10.8492 12.6536L16.9453 9.13409V0L2.93906 8.08671Z" fill="#001F61"/>
                                            <path d="M18 10.9609L12.9586 13.8716L18 16.7822L23.0414 13.8716L18 10.9609Z" fill="#001F61"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_300_2445">
                                            <rect width="36" height="36" fill="white"/>
                                            </clipPath>
                                        </defs>
                                        </svg>
                                        <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('CommunityForum.10')}</h1>
                                        <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('CommunityForum.11')}</p>
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[330px] bg-[#D1DEF8]">
                                    <div className="pt-[20px] mx-[20px] xl:mx-[40px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                        <g clip-path="url(#clip0_300_2474)">
                                            <path d="M2.75224 11.9873H1.05771C0.47524 11.9873 0.00302124 12.4595 0.00302124 13.042C0.00302124 13.6245 0.47524 14.0967 1.05771 14.0967H2.75224C4.14928 14.0452 4.14977 12.039 2.75224 11.9873Z" fill="#001F61"/>
                                            <path d="M34.9489 11.9873H33.2544C32.6719 11.9873 32.1997 12.4595 32.1997 13.042C32.1997 13.6245 32.6719 14.0967 33.2544 14.0967H34.9489C36.346 14.0452 36.3465 12.039 34.9489 11.9873Z" fill="#001F61"/>
                                            <path d="M6.4734 3.00383C7.49758 3.9553 8.91676 2.5371 7.96494 1.51229L6.76689 0.314236C6.35507 -0.097584 5.68724 -0.097584 5.27535 0.314236C4.86346 0.726057 4.86346 1.39388 5.27535 1.80578L6.4734 3.00383Z" fill="#001F61"/>
                                            <path d="M29.5333 23.0807C28.509 22.1293 27.09 23.5475 28.0417 24.5722L29.2398 25.7702C29.6515 26.182 30.3193 26.1823 30.7313 25.7702C31.1432 25.3584 31.1432 24.6906 30.7313 24.2787L29.5333 23.0807Z" fill="#001F61"/>
                                            <path d="M6.4734 23.0807L5.27535 24.2788C4.86346 24.6906 4.86346 25.3584 5.27535 25.7703C5.6871 26.1821 6.35486 26.1823 6.76689 25.7703L7.96494 24.5722C8.91648 23.548 7.49807 22.129 6.4734 23.0807Z" fill="#001F61"/>
                                            <path d="M29.2398 0.314078L28.0418 1.51213C27.6299 1.92395 27.6299 2.59178 28.0418 3.00367C28.4535 3.41542 29.1213 3.4157 29.5333 3.00367L30.7314 1.80562C31.6829 0.781375 30.2646 -0.637532 29.2398 0.314078Z" fill="#001F61"/>
                                            <path d="M22.959 14.0968L20.9699 16.0859C22.0789 18.2308 20.4289 20.9074 18.0033 20.875C16.1759 20.875 14.6892 19.3884 14.6892 17.5609C14.657 15.1354 17.3335 13.4852 19.4784 14.5943L21.7764 12.2962C21.9741 12.0984 22.2424 11.9873 22.5221 11.9873H29.7425C28.108 -2.22883 7.89335 -2.21842 6.26404 11.9873H13.0476L15.0367 9.99817C13.9278 7.85332 15.5777 5.17662 18.0034 5.20903C19.8308 5.20903 21.3175 6.69574 21.3175 8.52313C21.3497 10.9486 18.6732 12.5989 16.5283 11.4897L14.2303 13.7877C14.0326 13.9855 13.7643 14.0967 13.4846 14.0967H6.26481C6.53067 17.043 7.90158 19.7915 10.1195 21.7612C11.3273 22.8777 12.1108 24.378 12.351 25.9885C12.3536 25.9881 23.6517 25.9881 23.6556 25.9885C23.8957 24.3784 24.6793 22.8779 25.8873 21.7612C28.1052 19.7916 29.4761 17.043 29.7419 14.0967H22.959V14.0968Z" fill="#001F61"/>
                                            <path d="M12.4299 29.3024C12.4299 32.015 14.3782 34.28 16.9487 34.7745C16.8439 36.4087 19.1634 36.4077 19.0581 34.7745C21.6287 34.28 23.577 32.015 23.577 29.3024V28.0977H12.4299V29.3024Z" fill="#001F61"/>
                                            <path d="M19.2081 8.52309C19.2081 7.85885 18.6676 7.31836 18.0033 7.31836C16.4073 7.37897 16.4077 9.66743 18.0033 9.72783C18.6676 9.72776 19.2081 9.18734 19.2081 8.52309Z" fill="#001F61"/>
                                            <path d="M16.7986 17.5611C16.7986 18.2254 17.3391 18.7658 18.0033 18.7658C19.5993 18.7052 19.5989 16.4168 18.0033 16.3564C17.3391 16.3564 16.7986 16.8969 16.7986 17.5611Z" fill="#001F61"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_300_2474">
                                            <rect width="36" height="36" fill="white"/>
                                            </clipPath>
                                        </defs>
                                        </svg>
                                        <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('CommunityForum.12')}</h1>
                                        <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('CommunityForum.13')}</p>
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <div className='flex mt-[20px] gap-[20px]'>
                            <div onClick={handleClickPrev1} style={{ backgroundColor: bgColorPrev1 }} className="transition-all">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                    <path d="M9.64645 19.6464C9.45119 19.8417 9.45119 20.1583 9.64645 20.3536L12.8284 23.5355C13.0237 23.7308 13.3403 23.7308 13.5355 23.5355C13.7308 23.3403 13.7308 23.0237 13.5355 22.8284L10.7071 20L13.5355 17.1716C13.7308 16.9763 13.7308 16.6597 13.5355 16.4645C13.3403 16.2692 13.0237 16.2692 12.8284 16.4645L9.64645 19.6464ZM30 19.5L10 19.5V20.5L30 20.5V19.5Z" fill="#001F61"/>
                                </svg>
                            </div>
                            <div onClick={handleClickNext1} style={{ backgroundColor: bgColorNext1 }} className="transition-all">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                    <path d="M30.3536 20.3536C30.5488 20.1583 30.5488 19.8417 30.3536 19.6465L27.1716 16.4645C26.9763 16.2692 26.6597 16.2692 26.4645 16.4645C26.2692 16.6597 26.2692 16.9763 26.4645 17.1716L29.2929 20L26.4645 22.8284C26.2692 23.0237 26.2692 23.3403 26.4645 23.5355C26.6597 23.7308 26.9763 23.7308 27.1716 23.5355L30.3536 20.3536ZM10 20.5L30 20.5L30 19.5L10 19.5L10 20.5Z" fill="#001F61"/>
                                </svg>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CollaborativeLearning;