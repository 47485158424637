import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from '../components/Home/Main'
import Trading from "../components/Home/Trading";
import Stocks from "../components/Home/Stocks";
import SupportCenter from "../components/Home/SupportCenter";
import CommunityForum from "../components/Home/CommunityForum";
import AboutUs from "../components/Home/AboutUs";
import Join from "../components/Home/Join";

function Home () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <>
            <div className="overflow-x-hidden relative">
                <Main/>
                <div className="xl:mt-[240px]"></div>
                <LazyLoad><Trading/></LazyLoad>
                <LazyLoad><Stocks/></LazyLoad>
                <LazyLoad><SupportCenter/></LazyLoad>
                <LazyLoad><CommunityForum/></LazyLoad>
                <LazyLoad><AboutUs/></LazyLoad>
                <LazyLoad><Join/></LazyLoad>
            </div>
        </>
    );
}

export default Home;