import React from "react";

import iphone from '../../img/AssetMarket/img.png'
import girl from '../../img/AssetMarket/girl.png'

import Fade from 'react-reveal/Fade'
import Bounce from 'react-reveal/Bounce'

import { useTranslation } from "react-i18next";

function Main () {

    const { t } = useTranslation();

    return(
        <div className="max-w-[1280px] mx-auto">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="mt-[100px] xl:mt-[140px] pb-[30px] xl:pb-[59px]">
                    <h1 className="mx-[20px] xl:mx-0 manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium">{t('assetmarket.1')}</h1>
                    <p className="mx-[20px] xl:mx-0 mt-[40px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[550px] z-50 relative">{t('assetmarket.2')}</p>
                    <p className="mx-[20px] xl:mx-0 mt-[40px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[550px] z-50 relative">{t('assetmarket.3')}</p>
                    <p className="mx-[20px] xl:mx-0 mt-[40px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[550px] z-50 relative">{t('assetmarket.4')}</p>
                    <div className="mx-[20px] xl:mx-0 button bg-[#0349DA] mt-[40px] z-50 relative">
                        {t('assetmarket.5')}
                    </div>
                </div>
                <div className="flex flex-col items-end">
                    <Fade right><img src={iphone} alt="" className="absolute right-0"/></Fade>
                    <Bounce bottom><img src={girl} alt="" className="z-10 xl:absolute top-[200px] right-0"/></Bounce>
                </div>
            </div>
        </div>
    );
}

export default Main;