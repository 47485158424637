import React, { useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css';

import swiper01 from '../../img/SupportFaq/swiper6.png'
import swiper02 from '../../img/SupportFaq/swiper5.png'
import swiper03 from '../../img/SupportFaq/swiper4.png'
import swiper04 from '../../img/SupportFaq/swiper3.png'
import swiper05 from '../../img/SupportFaq/swiper2.png'
import swiper06 from '../../img/SupportFaq/swiper1.png'

import { useTranslation } from "react-i18next";

function EducationalResources () {

    const { t } = useTranslation();

    const [swiper1, setSwiper1] = useState({});

    const [bgColorPrev1, setBgColorPrev1] = useState('transparent');
    const [bgColorNext1, setBgColorNext1] = useState('transparent');

    const handleClickPrev1 = () => {
        if (bgColorPrev1 === 'transparent') {
            setBgColorPrev1('#D1DEF8');
          // Change back to transparent after 1 second (1000 milliseconds)
          setTimeout(() => {
            setBgColorPrev1('transparent');
          }, 100);
        }
        swiper1.slidePrev()
      };
    const handleClickNext1 = () => {
        if (bgColorNext1 === 'transparent') {
            setBgColorNext1('#D1DEF8');
            // Change back to transparent after 1 second (1000 milliseconds)
            setTimeout(() => {
                setBgColorNext1('transparent');
            }, 100);
        }
        swiper1.slideNext()
    };

    return(
        <div className="bg-[#F7F4F8] z-50 relative">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] pt-[80px] xl:pt-[160px]">
                <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium">{t('CommunityForum.14')}</h1>
                <p className="xl:max-w-[600px] textcolor text-[14px] xl:text-[16px] mt-[40px] roboto font-light">{t('CommunityForum.15')}</p>
                <div className="mt-[40px] xl:mt-[70px]">
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={1}
                        onInit={(e) => {
                            setSwiper1(e);
                        }}
                        loop={true}
                    >
                        <SwiperSlide>
                            <div className="flex justify-between flex-col xl:flex-row">
                                <div className="max-w-[600px]">
                                    <img src={swiper01} alt=""/>
                                </div>
                                <div className="mt-[20px] xl:mt-0">
                                    <p className="text-[16px] xl:text-[20px] manrope font-medium text-[#001F61]">{t('CommunityForum.16')}</p>
                                    <p className="max-w-[561px] roboto textcolor text-[14px] xl:text-[16px] font-light mt-[12px]">{t('CommunityForum.17')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex justify-between flex-col xl:flex-row">
                                <div className="max-w-[600px]">
                                    <img src={swiper02} alt="" />
                                </div>
                                <div className="mt-[20px] xl:mt-0">
                                    <p className="text-[16px] xl:text-[20px] manrope font-medium text-[#001F61]">{t('CommunityForum.18')}</p>
                                    <p className="max-w-[561px] roboto textcolor text-[14px] xl:text-[16px] font-light mt-[12px]">{t('CommunityForum.19')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex justify-between flex-col xl:flex-row">
                                <div className="max-w-[600px]">
                                    <img src={swiper03} alt="" />
                                </div>
                                <div className="mt-[20px] xl:mt-0">
                                    <p className="text-[16px] xl:text-[20px] manrope font-medium text-[#001F61]">{t('CommunityForum.20')}</p>
                                    <p className="max-w-[561px] roboto textcolor text-[14px] xl:text-[16px] font-light mt-[12px]">{t('CommunityForum.21')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex justify-between flex-col xl:flex-row">
                                <div className="max-w-[600px]">
                                    <img src={swiper04} alt="" />
                                </div>
                                <div className="mt-[20px] xl:mt-0">
                                    <p className="text-[16px] xl:text-[20px] manrope font-medium text-[#001F61]">{t('CommunityForum.22')}</p>
                                    <p className="max-w-[561px] roboto textcolor text-[14px] xl:text-[16px] font-light mt-[12px]">{t('CommunityForum.23')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex justify-between flex-col xl:flex-row">
                                <div className="max-w-[600px]">
                                    <img src={swiper05} alt="" />
                                </div>
                                <div className="mt-[20px] xl:mt-0">
                                    <p className="text-[16px] xl:text-[20px] manrope font-medium text-[#001F61]">{t('CommunityForum.24')}</p>
                                    <p className="max-w-[561px] roboto textcolor text-[14px] xl:text-[16px] font-light mt-[12px]">{t('CommunityForum.25')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex justify-between flex-col xl:flex-row">
                                <div className="max-w-[600px]">
                                    <img src={swiper06} alt="" />
                                </div>
                                <div className="mt-[20px] xl:mt-0">
                                    <p className="text-[16px] xl:text-[20px] manrope font-medium text-[#001F61]">{t('CommunityForum.26')}</p>
                                    <p className="max-w-[561px] roboto textcolor text-[14px] xl:text-[16px] font-light mt-[12px]">{t('CommunityForum.27')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="flex justify-end z-50 relative">
                        <div className='flex xl:mt-[-40px] gap-[20px]'>
                            <div onClick={handleClickPrev1} style={{ backgroundColor: bgColorPrev1 }} className="transition-all">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                    <path d="M9.64645 19.6464C9.45119 19.8417 9.45119 20.1583 9.64645 20.3536L12.8284 23.5355C13.0237 23.7308 13.3403 23.7308 13.5355 23.5355C13.7308 23.3403 13.7308 23.0237 13.5355 22.8284L10.7071 20L13.5355 17.1716C13.7308 16.9763 13.7308 16.6597 13.5355 16.4645C13.3403 16.2692 13.0237 16.2692 12.8284 16.4645L9.64645 19.6464ZM30 19.5L10 19.5V20.5L30 20.5V19.5Z" fill="#001F61"/>
                                </svg>
                            </div>
                            <div onClick={handleClickNext1} style={{ backgroundColor: bgColorNext1 }} className="transition-all">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                    <path d="M30.3536 20.3536C30.5488 20.1583 30.5488 19.8417 30.3536 19.6465L27.1716 16.4645C26.9763 16.2692 26.6597 16.2692 26.4645 16.4645C26.2692 16.6597 26.2692 16.9763 26.4645 17.1716L29.2929 20L26.4645 22.8284C26.2692 23.0237 26.2692 23.3403 26.4645 23.5355C26.6597 23.7308 26.9763 23.7308 27.1716 23.5355L30.3536 20.3536ZM10 20.5L30 20.5L30 19.5L10 19.5L10 20.5Z" fill="#001F61"/>
                                </svg>
                            </div>
                        </div>
                    </div>      
                </div>
            </div>
        </div>
    );
}

export default EducationalResources;