import React, { useState } from "react";

import Fade from 'react-reveal/Fade'

import frame from '../../img/TradingDashboard/frame.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination  } from 'swiper/modules';
import { useTranslation } from "react-i18next";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

function HowUse () {
    const [swiper, setSwiper] = useState({});

    const [bgColorPrev, setBgColorPrev] = useState('transparent');
    const [bgColorNext, setBgColorNext] = useState('transparent');

    const handleClickPrev = () => {
        if (bgColorPrev === 'transparent') {
            setBgColorPrev('#0349DA');
          // Change back to transparent after 1 second (1000 milliseconds)
          setTimeout(() => {
            setBgColorPrev('transparent');
          }, 100);
        }
        swiper.slidePrev()
      };
    const handleClickNext = () => {
        if (bgColorNext === 'transparent') {
            setBgColorNext('#0349DA');
            // Change back to transparent after 1 second (1000 milliseconds)
            setTimeout(() => {
                setBgColorNext('transparent');
            }, 100);
        }
        swiper.slideNext()
    };

    const { t } = useTranslation();

    return(
        <div className="bg-[#F7F4F8]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="pt-[60px] xl:pt-[120px] flex flex-col xl:flex-row justify-between pb-[80px] xl:pb-[160px]">
                    <div className="pt-0 xl:pt-[40px]">
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium xl:max-w-[528px]">{t('tradingdashboard.6')}</h1>
                        <p className="xl:max-w-[497px] textcolor font-light roboto text-[14px] xl:text-[16px] mt-[20px] xl:mt-[34px]">{t('tradingdashboard.7')}</p>
                        <Swiper 
                            modules={[Navigation, Autoplay, Pagination]}
                            slidesPerView={1}
                            onInit={(e) => {
                                setSwiper(e);
                            }}
                            className="xl:w-[600px] mt-[40px] xl:mt-[60px]"
                        >
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[262px] bg-[#D1DEF8]">
                                    <div className="flex mx-[20px] xl:mx-[40px] pt-[20px] xl:pt-[30px]">
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-bold">01</p>
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-medium ml-[20px] xl:ml-[30px]">{t('tradingdashboard.8')}</p>
                                    </div>
                                    <p className="mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[475px] xl:mx-[40px] mx-[20px]">{t('tradingdashboard.9')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[262px] bg-[#D1DEF8]">
                                    <div className="flex mx-[20px] xl:mx-[40px] pt-[20px] xl:pt-[30px]">
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-bold">02</p>
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-medium ml-[20px] xl:ml-[30px]">{t('tradingdashboard.10')}</p>
                                    </div>
                                    <p className="mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[475px] xl:mx-[40px] mx-[20px]">{t('tradingdashboard.11')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[262px] bg-[#D1DEF8]">
                                    <div className="flex mx-[20px] xl:mx-[40px] pt-[20px] xl:pt-[30px]">
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-bold">03</p>
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-medium ml-[20px] xl:ml-[30px]">{t('tradingdashboard.12')}</p>
                                    </div>
                                    <p className="mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[475px] xl:mx-[40px] mx-[20px]">{t('tradingdashboard.13')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[262px] bg-[#D1DEF8]">
                                    <div className="flex mx-[20px] xl:mx-[40px] pt-[20px] xl:pt-[30px]">
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-bold">04</p>
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-medium ml-[20px] xl:ml-[30px]">{t('tradingdashboard.14')}</p>
                                    </div>
                                    <p className="mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[475px] xl:mx-[40px] mx-[20px]">{t('tradingdashboard.15')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[262px] bg-[#D1DEF8]">
                                    <div className="flex mx-[20px] xl:mx-[40px] pt-[20px] xl:pt-[30px]">
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-bold">05</p>
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-medium ml-[20px] xl:ml-[30px]">{t('tradingdashboard.16')}</p>
                                    </div>
                                    <p className="mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[475px] xl:mx-[40px] mx-[20px]">{t('tradingdashboard.17')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[262px] bg-[#D1DEF8]">
                                    <div className="flex mx-[20px] xl:mx-[40px] pt-[20px] xl:pt-[30px]">
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-bold">06</p>
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-medium ml-[20px] xl:ml-[30px]">{t('tradingdashboard.18')}</p>
                                    </div>
                                    <p className="mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[475px] xl:mx-[40px] mx-[20px]">{t('tradingdashboard.19')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[262px] bg-[#D1DEF8]">
                                    <div className="flex mx-[20px] xl:mx-[40px] pt-[20px] xl:pt-[30px]">
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-bold">07</p>
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-medium ml-[20px] xl:ml-[30px]">{t('tradingdashboard.20')}</p>
                                    </div>
                                    <p className="mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[475px] xl:mx-[40px] mx-[20px]">{t('tradingdashboard.21')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="xl:w-[600px] h-[262px] bg-[#D1DEF8]">
                                    <div className="flex mx-[20px] xl:mx-[40px] pt-[20px] xl:pt-[30px]">
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-bold">08</p>
                                        <p className="text-[14px] xl:text-[20px] manrope text-[#001F61] font-medium ml-[20px] xl:ml-[30px]">{t('tradingdashboard.22')}</p>
                                    </div>
                                    <p className="mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[475px] xl:mx-[40px] mx-[20px]">{t('tradingdashboard.23')}</p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <div className='flex mt-[20px] gap-[20px]'>
                            <div onClick={handleClickPrev} style={{ backgroundColor: bgColorPrev }} className="transition-all">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                    <path d="M9.64645 19.6464C9.45119 19.8417 9.45119 20.1583 9.64645 20.3536L12.8284 23.5355C13.0237 23.7308 13.3403 23.7308 13.5355 23.5355C13.7308 23.3403 13.7308 23.0237 13.5355 22.8284L10.7071 20L13.5355 17.1716C13.7308 16.9763 13.7308 16.6597 13.5355 16.4645C13.3403 16.2692 13.0237 16.2692 12.8284 16.4645L9.64645 19.6464ZM30 19.5L10 19.5V20.5L30 20.5V19.5Z" fill="#001F61"/>
                                </svg>
                            </div>
                            <div onClick={handleClickNext} style={{ backgroundColor: bgColorNext }} className="transition-all">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                    <path d="M30.3536 20.3536C30.5488 20.1583 30.5488 19.8417 30.3536 19.6465L27.1716 16.4645C26.9763 16.2692 26.6597 16.2692 26.4645 16.4645C26.2692 16.6597 26.2692 16.9763 26.4645 17.1716L29.2929 20L26.4645 22.8284C26.2692 23.0237 26.2692 23.3403 26.4645 23.5355C26.6597 23.7308 26.9763 23.7308 27.1716 23.5355L30.3536 20.3536ZM10 20.5L30 20.5L30 19.5L10 19.5L10 20.5Z" fill="#001F61"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[40px] xl:mt-0">
                        <Fade right><img src={frame} alt="" /></Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowUse;