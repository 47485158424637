import React, { useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination  } from 'swiper/modules';

import { Link } from "react-router-dom"

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import a1 from '../../img/TradingDashboard/a5.svg'
import a2 from '../../img/TradingDashboard/a4.svg'
import a3 from '../../img/TradingDashboard/a3.svg'
import a4 from '../../img/TradingDashboard/a2.svg'
import a5 from '../../img/TradingDashboard/a1.svg'

import { useTranslation } from "react-i18next";

function UniqueTradingTools () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

    const [bgColorPrev, setBgColorPrev] = useState('transparent');
    const [bgColorNext, setBgColorNext] = useState('transparent');

    const handleClickPrev = () => {
        if (bgColorPrev === 'transparent') {
            setBgColorPrev('#0349DA');
          // Change back to transparent after 1 second (1000 milliseconds)
          setTimeout(() => {
            setBgColorPrev('transparent');
          }, 100);
        }
        swiper.slidePrev()
      };
    const handleClickNext = () => {
        if (bgColorNext === 'transparent') {
            setBgColorNext('#0349DA');
            // Change back to transparent after 1 second (1000 milliseconds)
            setTimeout(() => {
                setBgColorNext('transparent');
            }, 100);
        }
        swiper.slideNext()
    };

    return(
        <div className="bg-[#F7F4F8] z-50 relative">
            <div className="max-w-[1280px] xl:mx-auto pt-[80px] xl:pt-[160px] mx-[20px]">
                <div className="flex flex-col xl:flex-row justify-between">
                   <div>
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium max-w-[528px]">{t('tradingdashboard.39')}</h1>
                   </div>
                   <div>
                        <p className="xl:max-w-[620px] textcolor font-light roboto text-[14px] xl:text-[16px] mt-[20px] xl:mt-[34px]">{t('tradingdashboard.40')}</p>
                   </div>
                </div>
                <div className="mt-[60px]">

                    <Swiper 
                        modules={[Navigation, Autoplay, Pagination]}
                        spaceBetween={50}
                        slidesPerView={1}
                        onInit={(e) => {
                            setSwiper(e);
                        }}
                        breakpoints={{
                            300: {
                            slidesPerView: 1,
                            },
                            1280: {
                                slidesPerView: 2,
                            }
                        }}
                    >
                        <SwiperSlide>
                            <div className="xl:w-[600px] h-[394px] bg-[#D1DEF8]">
                                <div className="xl:pt-[40px] xl:ml-[40px] pt-[20px] ml-[20px] pb-[20px] xl:pb-0">
                                    <img src={a1} alt="" className=""/>
                                    <p className="mt-[30px] xl:mt-[50px] manrope text-[16px] xl:text-[20px] text-[#001F61] font-medium">{t('tradingdashboard.41')}</p>
                                    <p className="mt-[15px] xl:mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light mr-[20px] xl:mr-[40px]">{t('tradingdashboard.42')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:w-[600px] h-[394px] bg-[#D1DEF8]">
                                <div className="xl:pt-[40px] xl:ml-[40px] pt-[20px] ml-[20px]">
                                    <img src={a2} alt="" className=""/>
                                    <p className="mt-[30px] xl:mt-[50px] manrope text-[16px] xl:text-[20px] text-[#001F61] font-medium">{t('tradingdashboard.43')}</p>
                                    <p className="mt-[15px] xl:mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light mr-[20px] xl:mr-[40px]">{t('tradingdashboard.44')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:w-[600px] h-[394px] bg-[#D1DEF8]">
                                <div className="xl:pt-[40px] xl:ml-[40px] pt-[20px] ml-[20px]">
                                    <img src={a3} alt="" className=""/>
                                    <p className="mt-[30px] xl:mt-[50px] manrope text-[16px] xl:text-[20px] text-[#001F61] font-medium">{t('tradingdashboard.45')}</p>
                                    <p className="mt-[15px] xl:mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light mr-[20px] xl:mr-[40px]">{t('tradingdashboard.46')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:w-[600px] h-[394px] bg-[#D1DEF8]">
                                <div className="xl:pt-[40px] xl:ml-[40px] pt-[20px] ml-[20px]">
                                    <img src={a4} alt="" className=""/>
                                    <p className="mt-[30px] xl:mt-[50px] manrope text-[16px] xl:text-[20px] text-[#001F61] font-medium">{t('tradingdashboard.47')}</p>
                                    <p className="mt-[15px] xl:mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light mr-[20px] xl:mr-[40px]">{t('tradingdashboard.48')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:w-[600px] h-[394px] bg-[#D1DEF8]">
                                <div className="xl:pt-[40px] xl:ml-[40px] pt-[20px] ml-[20px]">
                                    <img src={a5} alt="" className=""/>
                                    <p className="mt-[30px] xl:mt-[50px] manrope text-[16px] xl:text-[20px] text-[#001F61] font-medium">{t('tradingdashboard.49')}</p>
                                    <p className="mt-[15px] xl:mt-[20px] textcolor roboto text-[14px] xl:text-[16px] font-light mr-[20px] xl:mr-[40px]">{t('tradingdashboard.50')}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className='flex mt-[20px] gap-[20px]'>
                        <div onClick={handleClickPrev} style={{ backgroundColor: bgColorPrev }} className="transition-all">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                <path d="M9.64645 19.6464C9.45119 19.8417 9.45119 20.1583 9.64645 20.3536L12.8284 23.5355C13.0237 23.7308 13.3403 23.7308 13.5355 23.5355C13.7308 23.3403 13.7308 23.0237 13.5355 22.8284L10.7071 20L13.5355 17.1716C13.7308 16.9763 13.7308 16.6597 13.5355 16.4645C13.3403 16.2692 13.0237 16.2692 12.8284 16.4645L9.64645 19.6464ZM30 19.5L10 19.5V20.5L30 20.5V19.5Z" fill="#001F61"/>
                            </svg>
                        </div>
                        <div onClick={handleClickNext} style={{ backgroundColor: bgColorNext }} className="transition-all">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                <path d="M30.3536 20.3536C30.5488 20.1583 30.5488 19.8417 30.3536 19.6465L27.1716 16.4645C26.9763 16.2692 26.6597 16.2692 26.4645 16.4645C26.2692 16.6597 26.2692 16.9763 26.4645 17.1716L29.2929 20L26.4645 22.8284C26.2692 23.0237 26.2692 23.3403 26.4645 23.5355C26.6597 23.7308 26.9763 23.7308 27.1716 23.5355L30.3536 20.3536ZM10 20.5L30 20.5L30 19.5L10 19.5L10 20.5Z" fill="#001F61"/>
                            </svg>
                        </div>
                    </div>
                    <p className="xl:max-w-[600px] textcolor font-light roboto text-[14px] xl:text-[16px] mt-[30px] xl:mt-[60px]">{t('tradingdashboard.51')}</p>
                    <Link to='https://user.cgl-limited.net'><div className="button bg-[#0349DA] mt-[40px] z-50 relative">
                        Start Trading!
                    </div></Link>
                </div>
            </div>
        </div>
    );
}

export default UniqueTradingTools;