import React from 'react';

import logo from "../img/logo.png";

import { useTranslation } from "react-i18next";

function Footer() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#F7F4F8] z-10 relative'>
      <div className='flex flex-col xl:flex-row container mx-auto pt-[160px]'>
        <img src={logo} alt="" className='w-[55px] h-[55px] ml-[30px] xl:ml-0'/>
        <div className='ml-[30px] xl:ml-[150px] gap-[15px] flex flex-col mt-[30px] xl:mt-0'>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.1')}</p>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.2')}</p>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.3')}</p>
        </div>
        <div className='ml-[30px] xl:ml-[450px] gap-[15px] flex flex-col mt-[15px] xl:mt-0'>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.4')}</p>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.5')}</p>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.6')}</p>
        </div>
      </div>
      <div className='flex flex-col container mx-auto pl-[30px] xl:pl-[205px] pt-[40px]'>
        <p className='textcolor roboto text-[12px] font-normal'>{t('footer.8')}</p>
        <p className='textcolor roboto text-[12px] font-light mt-[6px] pr-[30px] xl:pr-0'>{t('footer.7')}</p>
        <p className='flex justify-end pb-[150px] mt-[14px] roboto text-[16px] textcolor pr-[30px] xl:pr-0'>Trade Connect © 2024. All rights reserved.</p>      
      </div>
    </div>
  );
}

export default Footer;