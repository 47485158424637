import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from '../components/AssetMarket/Main'
import TradeWhateverSwitcher from "../components/AssetMarket/TradeWhateverSwitcher";
import ExploreTrading from "../components/AssetMarket/ExploreTrading";
import Join from "../components/Home/Join";

function AssetMarket () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <>
            <div className="overflow-hidden relative">
                <Main/>
                <LazyLoad><TradeWhateverSwitcher/></LazyLoad>
                <LazyLoad><ExploreTrading/></LazyLoad>
                <div className="mt-[40px] xl:mt-[80px]"></div>
                <LazyLoad><Join/></LazyLoad>
                <div className="mt-[40px] xl:mt-[80px]"></div>
            </div>
        </>
    );
}

export default AssetMarket;