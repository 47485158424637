import React, { useState } from "react";

import { Link } from "react-router-dom"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css';

import swiperone1 from '../../img/AssetMarket/swiper(1)7.svg'
import swiperone2 from '../../img/AssetMarket/swiper(1)6.svg'
import swiperone3 from '../../img/AssetMarket/swiper(1)5.svg'
import swiperone4 from '../../img/AssetMarket/swiper(1)4.svg'
import swiperone5 from '../../img/AssetMarket/swiper(1)3.svg'
import swiperone6 from '../../img/AssetMarket/swiper(1)2.svg'
import swiperone7 from '../../img/AssetMarket/swiper(1)1.svg'

import swipertwo1 from '../../img/AssetMarket/swiper(2)1.svg'
import swipertwo2 from '../../img/AssetMarket/swiper(2)2.svg'
import swipertwo3 from '../../img/AssetMarket/swiper(2)3.svg'
import swipertwo4 from '../../img/AssetMarket/swiper(2)4.svg'
import swipertwo5 from '../../img/AssetMarket/swiper(2)5.svg'

import { useTranslation } from "react-i18next";

function ExploreTrading () {

    const { t } = useTranslation();

    const [swiper1, setSwiper1] = useState({});

    const [bgColorPrev1, setBgColorPrev1] = useState('transparent');
    const [bgColorNext1, setBgColorNext1] = useState('transparent');

    const handleClickPrev1 = () => {
        if (bgColorPrev1 === 'transparent') {
            setBgColorPrev1('#D1DEF8');
          // Change back to transparent after 1 second (1000 milliseconds)
          setTimeout(() => {
            setBgColorPrev1('transparent');
          }, 100);
        }
        swiper1.slidePrev()
      };
    const handleClickNext1 = () => {
        if (bgColorNext1 === 'transparent') {
            setBgColorNext1('#D1DEF8');
            // Change back to transparent after 1 second (1000 milliseconds)
            setTimeout(() => {
                setBgColorNext1('transparent');
            }, 100);
        }
        swiper1.slideNext()
    };
    const [swiper2, setSwiper2] = useState({});

    const [bgColorPrev2, setBgColorPrev2] = useState('transparent');
    const [bgColorNext2, setBgColorNext2] = useState('transparent');

    const handleClickPrev2 = () => {
        if (bgColorPrev2 === 'transparent') {
            setBgColorPrev2('#FFF');
          // Change back to transparent after 1 second (1000 milliseconds)
          setTimeout(() => {
            setBgColorPrev2('transparent');
          }, 100);
        }
        swiper2.slidePrev()
      };
    const handleClickNext2 = () => {
        if (bgColorNext2 === 'transparent') {
            setBgColorNext2('#FFF');
            // Change back to transparent after 1 second (1000 milliseconds)
            setTimeout(() => {
                setBgColorNext2('transparent');
            }, 100);
        }
        swiper2.slideNext()
    };
    return(
        <div className="flex flex-col xl:flex-row">
            <div className="xl:w-1/2 bg-[#FFF]  pb-[50px]">
                <div className="max-w-[640px] ml-auto">
                    <div className="mx-[20px] xl:mx-0">
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium max-w-[558px] mt-[20px] xl:mt-[40px]">{t('assetmarket.22')}</h1>
                        <p className="mt-[40px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[558px] z-50 relative">{t('assetmarket.23')}</p>
                        <div className="mt-[30px] xl:mt-[60px]">
                            <Swiper
                                modules={[Navigation]}
                                slidesPerView={1}
                                onInit={(e) => {
                                    setSwiper1(e);
                                }}
                                loop={true}
                            > 
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#D1DEF8]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swiperone1} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('assetmarket.24')}</h1>
                                            <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.25')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#D1DEF8]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swiperone1} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('assetmarket.26')}</h1>
                                            <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.27')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#D1DEF8]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swiperone2} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('assetmarket.28')}</h1>
                                            <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.29')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#D1DEF8]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swiperone3} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('assetmarket.30')}</h1>
                                            <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.31')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#D1DEF8]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swiperone4} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('assetmarket.32')}</h1>
                                            <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.33')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#D1DEF8]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swiperone5} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('assetmarket.34')}</h1>
                                            <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.35')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#D1DEF8]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swiperone6} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('assetmarket.36')}</h1>
                                            <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.37')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#D1DEF8]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swiperone7} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#001F61] mt-[20px] xl:mt-[40px]">{t('assetmarket.38')}</h1>
                                            <p className="textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.39')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <div className='flex mt-[20px] gap-[20px]'>
                                <div onClick={handleClickPrev1} style={{ backgroundColor: bgColorPrev1 }} className="transition-all">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                        <path d="M9.64645 19.6464C9.45119 19.8417 9.45119 20.1583 9.64645 20.3536L12.8284 23.5355C13.0237 23.7308 13.3403 23.7308 13.5355 23.5355C13.7308 23.3403 13.7308 23.0237 13.5355 22.8284L10.7071 20L13.5355 17.1716C13.7308 16.9763 13.7308 16.6597 13.5355 16.4645C13.3403 16.2692 13.0237 16.2692 12.8284 16.4645L9.64645 19.6464ZM30 19.5L10 19.5V20.5L30 20.5V19.5Z" fill="#001F61"/>
                                    </svg>
                                </div>
                                <div onClick={handleClickNext1} style={{ backgroundColor: bgColorNext1 }} className="transition-all">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                        <path d="M30.3536 20.3536C30.5488 20.1583 30.5488 19.8417 30.3536 19.6465L27.1716 16.4645C26.9763 16.2692 26.6597 16.2692 26.4645 16.4645C26.2692 16.6597 26.2692 16.9763 26.4645 17.1716L29.2929 20L26.4645 22.8284C26.2692 23.0237 26.2692 23.3403 26.4645 23.5355C26.6597 23.7308 26.9763 23.7308 27.1716 23.5355L30.3536 20.3536ZM10 20.5L30 20.5L30 19.5L10 19.5L10 20.5Z" fill="#001F61"/>
                                    </svg>
                                </div>
                            </div>
                            <p className="mt-[40px] textcolor roboto text-[14px] xl:text-[16px] font-medium xl:max-w-[558px] z-50 relative">{t('assetmarket.40')}</p>
                            <Link to='https://user.cgl-limited.net'><div className="button bg-[#0349DA] mt-[40px] z-50 relative">
                                {t('assetmarket.41')}
                            </div></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="xl:w-1/2 bg-[#D1DEF8] pb-[50px]">
                <div className="max-w-[640px] mr-auto">
                    <div className="xl:ml-[50px] mx-[20px] xl:mx-0">
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium max-w-[558px] mt-[20px] xl:mt-[40px]">{t('assetmarket.42')}</h1>
                        <p className="mt-[40px] textcolor roboto text-[14px] xl:text-[16px] font-light xl:max-w-[558px] z-50 relative">{t('assetmarket.43')}</p>
                        <div className="mt-[30px] xl:mt-[60px]">
                            <Swiper
                                modules={[Navigation]}
                                slidesPerView={1}
                                onInit={(e) => {
                                    setSwiper2(e);
                                }}
                                loop={true}
                            > 
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#001F61]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swipertwo1} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#FFF] mt-[20px] xl:mt-[40px]">{t('assetmarket.44')}</h1>
                                            <p className="textcolorwhite roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.45')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#001F61]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swipertwo2} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#FFF] mt-[20px] xl:mt-[40px]">{t('assetmarket.46')}</h1>
                                            <p className="textcolorwhite roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.47')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#001F61]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swipertwo3} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#FFF] mt-[20px] xl:mt-[40px]">{t('assetmarket.48')}</h1>
                                            <p className="textcolorwhite roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.49')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#001F61]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swipertwo4} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#FFF] mt-[20px] xl:mt-[40px]">{t('assetmarket.50')}</h1>
                                            <p className="textcolorwhite roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.51')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="xl:w-[558px] h-[374px] bg-[#001F61]">
                                        <div className="mx-[20px] xl:mx-[40px]">
                                            <img src={swipertwo5} alt="" className="pt-[20px] xl:pt-[30px]"/>
                                            <h1 className="manrope text-[16px] xl:text-[20px] font-medium text-[#FFF] mt-[20px] xl:mt-[40px]">{t('assetmarket.52')}</h1>
                                            <p className="textcolorwhite roboto text-[14px] xl:text-[16px] font-light mt-[20px]">{t('assetmarket.53')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <div className='flex mt-[20px] gap-[20px]'>
                                <div onClick={handleClickPrev2} style={{ backgroundColor: bgColorPrev2 }} className="transition-all">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                        <path d="M9.64645 19.6464C9.45119 19.8417 9.45119 20.1583 9.64645 20.3536L12.8284 23.5355C13.0237 23.7308 13.3403 23.7308 13.5355 23.5355C13.7308 23.3403 13.7308 23.0237 13.5355 22.8284L10.7071 20L13.5355 17.1716C13.7308 16.9763 13.7308 16.6597 13.5355 16.4645C13.3403 16.2692 13.0237 16.2692 12.8284 16.4645L9.64645 19.6464ZM30 19.5L10 19.5V20.5L30 20.5V19.5Z" fill="#001F61"/>
                                    </svg>
                                </div>
                                <div onClick={handleClickNext2} style={{ backgroundColor: bgColorNext2 }} className="transition-all">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <rect x="0.5" y="0.5" width="39" height="39" stroke="#001F61"/>
                                        <path d="M30.3536 20.3536C30.5488 20.1583 30.5488 19.8417 30.3536 19.6465L27.1716 16.4645C26.9763 16.2692 26.6597 16.2692 26.4645 16.4645C26.2692 16.6597 26.2692 16.9763 26.4645 17.1716L29.2929 20L26.4645 22.8284C26.2692 23.0237 26.2692 23.3403 26.4645 23.5355C26.6597 23.7308 26.9763 23.7308 27.1716 23.5355L30.3536 20.3536ZM10 20.5L30 20.5L30 19.5L10 19.5L10 20.5Z" fill="#001F61"/>
                                    </svg>
                                </div>
                            </div>
                            <p className="mt-[40px] textcolor roboto text-[16px] xl:text-[20px] font-medium xl:max-w-[558px] z-50 relative">{t('assetmarket.54')}</p>
                            <Link to='https://user.cgl-limited.net'><div className="button bg-[#0349DA] mt-[51px] z-50 relative">
                                {t('assetmarket.55')}
                            </div></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExploreTrading;