import React from "react";

import community from '../../img/Home/community.png'

import Fade from 'react-reveal/Fade'

import { Link } from "react-router-dom"

import { useTranslation } from "react-i18next";

function CommunityForum () {

    const { t } = useTranslation();

    return(
        <div className="bg-[#001F61] z-50 relative">
            <div className="max-w-[1280px] xl:mx-auto pt-[40px] xl:pt-[67px] pb-[66px] xl:pb-[73px] mx-[20px]">
                <div className="flex flex-col-reverse xl:flex-row justify-between">
                    <Fade left><div className="mt-[40px] xl:mt-[60px] mx-auto xl:mx-0">
                        <img src={community} alt="" />
                    </div></Fade>
                    <div>
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#FFF] font-medium">{t('main.13')}</h1>
                        <p className="textcolorwhite mt-[40px] text-[14px] xl:text-[16px] xl:max-w-[600px] roboto font-light">{t('main.14')}</p>
                        <p className="textcolorwhite mt-[20px] text-[14px] xl:text-[16px] xl:max-w-[600px] roboto font-light">{t('main.15')}</p>
                        <p className="textcolorwhite mt-[20px] text-[14px] xl:text-[16px] xl:max-w-[600px] roboto font-light">{t('main.16')}</p>
                        <p className="textcolorwhite mt-[20px] text-[14px] xl:text-[16px] xl:max-w-[600px] roboto font-light">{t('main.17')}</p>
                        <Link to='https://user.cgl-limited.net'><div className="button border border-[#FFF] mt-[40px] z-50 relative">
                            <p className="text-[#FFF] underline">{t('main.18')}</p>
                        </div></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommunityForum;