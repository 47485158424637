import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from "../components/TradingDashboard/Main";
import Realtime from "../components/TradingDashboard/RealTime";
import UniqueTradingTools from "../components/TradingDashboard/UniqueTradingTools";
import HowUse from "../components/TradingDashboard/HowUse";
import Join from "../components/Home/Join";

function TradingDashboard () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <>
            <div className="overflow-x-hidden relative">
                <Main/>
                <div className="xl:mt-[180px]"></div>
                <LazyLoad><Realtime/></LazyLoad>
                <LazyLoad><UniqueTradingTools/></LazyLoad>
                <LazyLoad><HowUse/></LazyLoad>
                <LazyLoad><Join/></LazyLoad>
            </div>
        </>
    );
}

export default TradingDashboard;