import React from "react";

import note from '../../img/Home/img.png'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom"

function AboutUs () {

    const { t } = useTranslation();

    return(
        <div className="bg-[#F7F4F8] z-50 relative">
            <div className="max-w-[1280px] xl:mx-auto pt-[120px] xl:pt-[200px] mx-[20px]">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div>
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium">{t('main.19')}</h1>
                        <p className="xl:max-w-[600px] textcolor mt-[40px] roboto font-light">{t('main.20')}</p>
                        <p className="xl:max-w-[600px] textcolor mt-[20px] roboto font-light">{t('main.21')}</p>
                        <p className="xl:max-w-[600px] textcolor mt-[20px] roboto font-light">{t('main.22')}</p>
                        <p className="xl:max-w-[600px] textcolor mt-[20px] roboto font-light">{t('main.23')}</p>
                        <Link to='https://user.cgl-limited.net'><div className="button border border-[#0349DA] mt-[40px] z-50 relative">
                            <p className="text-[#0349DA] underline">{t('main.24')}</p>
                        </div></Link>
                    </div>
                    <Fade right><div className="mx-auto xl:mx-0">
                        <img style={{ transform: 'translateX(50px)' }} src={note} alt=""/>
                    </div></Fade>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;