import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom"

const faqData = [
    {
        question: 'supportfaq.4',
        answer: 'supportfaq.5'
    },
    {
        question: 'supportfaq.6',
        answer: 'supportfaq.7'
    },
    {
        question: 'supportfaq.8',
        answer: 'supportfaq.9'
    },
    {
        question: 'supportfaq.10',
        answer: 'supportfaq.11'
    },
    {
        question: 'supportfaq.12',
        answer: 'supportfaq.13'
    },
    {
        question: 'supportfaq.14',
        answer: 'supportfaq.15'
    },
    {
        question: 'supportfaq.16',
        answer: 'supportfaq.17'
    },
    {
        question: 'supportfaq.18',
        answer: 'supportfaq.19'
    },
    {
        question: 'supportfaq.20',
        answer: 'supportfaq.21'
    },
    {
        question: 'supportfaq.22',
        answer: 'supportfaq.23'
    }
]

function FAQSecond () {
    
    const [openIndex, setOpenIndex] = useState(null);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const { t } = useTranslation();

    return(
        <div className="bg-[#F7F4F8] z-50 relative pb-[80px] xl:pb-[160px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                <div className="flex flex-col pt-[80px] xl:pt-[160px]">
                    <div>
                        <h1 className="manrope text-[28px] xl:text-[40px] text-[#001F61] font-medium max-w-[528px]">{t('supportfaq.24')}</h1>
                    </div>
                    <div>
                        <p className="xl:max-w-[620px] textcolor font-light roboto text-[20px] xl:text-[24px] mt-[10px] xl:mt-[16px]">{t('supportfaq.25')}</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-2 mt-[20px] xl:mt-[40px]">
                    {faqData.map((faq, index) => (
                        <div key={index}>
                            <div className={`w-[600px] flex justify-between mt-[20px] h-[60px] transition-all duration-300 ${openIndex === index ? 'h-auto bg-[#FFF]' : 'bg-[#FFF]'}`}>
                                <div>
                                    <div className="flex items-center">
                                        <div className="w-[60px] h-[60px] flex items-center justify-center bg-[#D1DEF8]">
                                            <div className='cursor-pointer flex mx-[20px]' onClick={() => toggleText(index)}>
                                                <svg className={`${openIndex === index ? 'transition-transform -rotate-45' : 'transition-transform -rotate-90'}`} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <g clip-path="url(#clip0_300_2776)">
                                                        <path d="M19.2188 9.21875H10.7812V0.78125C10.7812 0.349766 10.4315 0 10 0C9.56852 0 9.21875 0.349766 9.21875 0.78125V9.21875H0.78125C0.349766 9.21875 0 9.56852 0 10C0 10.4315 0.349766 10.7812 0.78125 10.7812H9.21875V19.2188C9.21875 19.6502 9.56852 20 10 20C10.4315 20 10.7812 19.6502 10.7812 19.2188V10.7812H19.2188C19.6502 10.7812 20 10.4315 20 10C20 9.56852 19.6502 9.21875 19.2188 9.21875Z" fill="#001F61"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_300_2776">
                                                            <rect width="20" height="20" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <p className='manrope font-medium text-[14px] xl:text-[16px] text-[#001F61] ml-[40px]'>{t(faq.question)}</p>
                                    </div>
                                    {openIndex === index && (
                                    <div className="flex">
                                        <div className="min-w-[60px] bg-[#D1DEF8]"></div>
                                        <p className='textcolor roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] ml-[40px]'>{t(faq.answer)}</p>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <p className="xl:max-w-[600px] textcolor font-light roboto text-[14px] xl:text-[16px] mt-[30px] xl:mt-[60px]">{t('supportfaq.26')}</p>
                <Link to='https://user.cgl-limited.net'><div className="button bg-[#0349DA] mt-[40px] z-50 relative">
                    {t('supportfaq.27')}
                </div></Link>
            </div>
        </div>
    );
}

export default FAQSecond;